/*import path changes go ere:*/
@common-less-dir-path: '../../common/less/';
@common-less-dir-path-platform: "../../../common/less/platform/";

/* needs to be top of stylesheet for chrome to apply css var*/
:root {
    --masthead-colour-filter: brightness(0) saturate(100%) invert(13%) sepia(82%) saturate(1467%) hue-rotate(209deg) brightness(95%) contrast(89%);
}

@import "platform/base.less";
@import "base/__variables.less";

@import "base.less";



 