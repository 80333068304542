

.volume-issue-list {
    padding-left: 20px;

}

.volume-item {
    h3 > a {
        text-decoration: none;
        cursor: pointer;
        color: @btn-default-bg;
        
        
        &.expanded:before {
            display: inline-block;
            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            content: @fa-var-minus-square-o;
        }
         &:not(.expanded):before {
            display: inline-block;
            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            content: @fa-var-plus-square-o;
        }
        &.expandthis {
            color: @brand-primary;
            font-weight: bold;
        }
    }
    
    > h3 {
        margin-bottom: 5px;
    }
    
    .issue > a.active {
        color: @link-color;
        text-decoration: @font-decoration-browse-item-title;
        font-weight: bold;
    }

    
}

 .minus {
    display: none;
}

.description.contain {
    > p { 
    //text is initally invisible not display none to allow the jQuery Elipses plugin to resize text!
    visibility:hidden;
    br {
        display:none;
    }
    &.makevisible {
        //on the callback of the jQuery elipses plugin we apply the class hiddenElemnt witch does a display none... 
        // we set visibility to visible to allow the text to be displayed on slideDown..
        visibility: visible; //overide
    }
}

}


    .issuecontents {
        .img-responsive{
            margin-bottom: 10px;
            max-width: 184px;
            width: 100%;
        }

    }

.tocheading2 {
font-weight: bold;
    padding-top: 15px; 
    display: block;
}

#journalspage {
	.coverImageContainer {
		width: 33%;
	}
	.articleMetadataInner {
		h4 {
			margin-top: 0px;
		}
	}
	.cover {
		max-width: none !important;
		width:100%;
		max-height: 80px;
	}
}

.margin-bottom--40 {
	margin-bottom: 40px;
}
