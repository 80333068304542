
	/* ################################################################################# 
	   #################################################################################
	
								   	Desktop View 
	*/
/*.collectionsContainer{
	margin-top:28px; 
}*/



	/* Desktop */
@media (min-width:@screen-desktop) { 
	
	header .header-container .navbar-mobile {
	    display:none;
	}
	.header .header-container {
        position: relative;
        margin: 10px auto;

       
    }
	
	.sidebar-pub2web-element {
	  	width: 100%;
	 	padding:0;
	 	}
	

	/* for tablet and above we do want padding for the main container!  */
	.main-content-container {
		padding:0px 15px;
	}
	/* remove added padding at mobile view */
	.add-padding {
		padding:0px 0px;
	}
	/* apply left/right padding for desktop view */
	.desktop-padding {
		padding: @main-content-box-padding;
	}	

	/* show or fix elements that have been started off as mobile elements and now need to change for Desktop*/
	.btn.btn-default.cart-button {
		display:none; 
	}
	.secondary-nav .cart {
		display:block; 
	}
	.home-button {
		display:none;
	}
	/* ### generic layout positions */
	.clear-float-Left {
		float:right;
		clear:both;
	}

	.advanced-search {
		float:right;
		clear:both;
	}

	.header-sidebar {
		.share-nav, .tools-nav {
		    position: static;
		    top: auto;
		    box-shadow: none;
		    .panel-heading { 
		    	display: block; 
		    }
		}
	}
	
	/* ##### main container */		
	.main-content-container {
		min-height: 800px;
	}
			

	/* ### Tools and Share Nav */
	.side-bar-utilities {
		h3 {
			display:block;
		}
	}
    
	.mobile-home-link {
		display:none;
	}
			
	/* ###### side bar */
	    
	 /* sign in area */
	.sign-in-container {
	    	position:static;
	 }
	 

	 .sign-in-container {
	 	border:none;	 
	 }
	 
	header {
         .form-group {
              width:70%;
              float:none;
           }
          .navbar-form .btn-default {
                margin-left:0;
               }
      } 

	 

		 
 /* auto complete drop down size */ 	

    
	.ui-autocomplete {
	    z-index:6 !important;
	} 
	
	 /* Search Results or any other page with a similar layout!  */
/* 	#searchresultspage .footer {
    	display:none;
    } */
   

	.browse-navigation-option 
	   .filter-button{
		  display:none;
	}
	.facet-container {
		.facets-toggle-span{
			display:none;
		}
		.list-group {
			display:block;
		}
}	
	
	/* due to facets in a modal window we now need to remove all modal styling features to render normally */
	.browse-facet-pane .modal.fade {
		display:block !important;
		position:relative;
		overflow: visible;
		opacity: 1;
		.modal-lg {
			width:auto;
			margin:inherit;
		}
		.modal-dialog{
			transform:none;
		}
		.modal-content {
			border:none;
			box-shadow:none;
			.modal-header{
				display:none;
			}
		}
	}

 //top header NAVIGATION on hover display drop down .
 .navbar-nav-main-item:hover { 
    > .dropdown-menu {
/*        display: block;
*/    }
    > .dropdown-toggle {
       //background-color: @dropdown-bg;
       color: @navbar-inverse-link-hover-color;
    }
 }

 

}
	
@media (min-width:@screen-tablet) and (max-width:@screen-md-max){
	
	.container {
		width:100%;
	}
	
	/* ######  Main nav */
	.main-nav .home {
	    display:block;
	}
	.mobile-home-link {
		display:none;
	}
	.fa.fa-home {
		display:inline-block;
	}
    /* ######  end of Main nav */
	    

}	



@media (min-width:@screen-lg-desktop){ 
	  /* ######  Main nav */
		.main-nav .home {
		    display:block;
		}	
	

		
	   .home-button {
            display:none;
        }
		
	
		


}	
