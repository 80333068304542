#footer{
    background-color: @footer-background-colour;
    color: @footer-default-text-colour;
    position:relative;
    margin-top: auto;
    border-top: @footer-top-border;
    width: 100%;

    a:link, a:active, a:visited { 
        color: @footer-link-color;   
    }
    a:hover {
        text-decoration: underline;
    }

    h3 {
        color: @footer-heading-text-colour; 
        font-weight: @footer-heading-weight;
    }
    .backToTopLink{
        a{
            position:fixed;
            background: @back-to-top-link-background;
            color: @back-to-top-link;
            bottom: 67px;
            right: 3%;
            padding:8px;
            border-radius:50%;
            outline:none; 
            z-index:8; 
            i{
                font-size:23px; 
            }
            &:hover{
                background:#000; 
            }  
        }
    }
    
    p {
        float:left;
    }
    
    .footer-issn-text {
        float:right;
    }
}