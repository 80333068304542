.smaller-text {
    font-size:@font-size-small;
    &:link {
        text-decoration: underline;
    }
}

.horizontally-centered-content {
   display: flex;
     align-items: center;
  justify-content: center;
       
 }

.sidebar-pub2web-element.altmetrics > * {
   padding: 10px;
}

#homepage #mobile-share-button {
    display: none;
}

