
/* Panel's Variable override */ 
@panel-body-padding:          15px 0;
@panel-heading-padding:       10px 0;
@panel-inner-border:          @dborder-color;
@panel-default-heading-bg:    @bg-transparent;
@panel-default-border:        @bg-transparent;


/* Panel override approach across the site of which we dont have variables availabe */
.panel{
	box-shadow: none;
	> .panel-heading{
		border-color: @panel-inner-border;
		.panel-title{
			font-size: @font-size-h3;
			font-weight: @panel-font-weight;
		}
	}
}


/* Modify search section for flat design ( Border less ) styles */
.searchRefine {
	&.navbar{
		border: none;
		background-color: @bg-transparent;
        float: left;
        clear: both;
        width: 100%;
		.modifyThisSearch{
			padding-left: 0;
		}
	}
	.modifyThisSearch .modifyThisSearch__button {
		margin-left: -15px;
	}
}

/*For borderless login box*/


@media (min-width: @screen-md-max) {
	h1{
		font-size: @font-size-h1;
	}

	.left-padding{
		padding-left: 15px;
	}
	.header-sidebar{
		.navbar-right {
			margin-right: 0;
		}
	}
}

.content__description--show-hide {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 50%;
	text-align: right;
	background: #f6f6f6;
	background: linear-gradient(90deg, rgba(246, 246, 246, 0) 0%, #f6f6f6 100%);
	margin-right: 15px;
}

.content__description--show-hide a:hover {
	text-decoration: none;
}

.content__description--number-of-line {
	display: none;
}

