/*rtl:raw:
.fa-rss, .fa-share-alt, .fa-print, 
.fa-share-alt-square, .fa-wrench, 
.fa-thumbs-o-up, .fa-key, fa-search,
.fa-file-o, .fa-book, .fa-link,
.fa-paper-plane, .fa-angle-double-right,
.fa-angle-double-left, .fa-chevron-right, 
.fa-chevron-left  {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
    -webkit-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
}
.arrow.next, .arrow.prev  {
        transform: rotate(180deg);
        display: inline-block;
}
 
.spacing .affiliation-details .affiliations_number {
 display:none;
}

#tabbedpages #abstract_content { 
  -moz-box-sizing: content-box;
}

 .cycle-slideshow .cycle-prev 
 .cycle-slideshow__chevron-container 
 .cycle-slideshow__chevron-left {
    padding-right: 10px;
    padding-left: 0;
 }
  .cycle-slideshow .cycle-next 
  .cycle-slideshow__chevron-container 
  .cycle-slideshow__chevron-right {
    padding-left: 10px;
    padding-right: 0;
 }
 #bellowheadercontainer .form-control {
    text-align:right; 
  }
 #email-form .form-control {
   text-align:right;
 }
 .CodeMirror {
    direction: ltr;
 }
 .fancybox-container .fancybox-buttons {
    right: auto;
    left: 0;
  }
  
  .fancybox-buttons .fancybox-button--close {
     float: left;
   }
 
*/

.graphOutput {
    /*rtl:begin:ignore*/
    direction: ltr;
    /*rtl:end:ignore*/
}

.title-box li {
    unicode-bidi: plaintext;
}