#cookie-bar { background-color:rgba(17,17,17,0.7); line-height:24px; color:#eeeeee; text-align:center; padding:3px 0;}
#cookie-bar.fixed {position:fixed; bottom:0; left:0; width:100%; z-index: 2;}
#cookie-bar p {margin:0; padding:0;}
#cookie-bar a {text-decoration:underline; color:@default-background-colour; }
#cookie-bar .cb-enable, #cookie-bar .cb-disable,#cookie-bar .cb-policy {color:@default-background-colour; display:inline-block; border-radius:3px; text-decoration:none; padding:0 6px; margin-left:8px;}
#cookie-bar .cb-enable {background:@btn-primary-bg;}
#cookie-bar .cb-enable:hover {background:darken(@btn-primary-bg, 10%);}
#cookie-bar .cb-disable {background:#990000;}
#cookie-bar .cb-disable:hover {background:#bb0000;}
#cookie-bar .cb-policy {background:#0033bb;}
#cookie-bar .cb-policy:hover {background:#0055dd;}
