
/* ########################################################## */
/* ##### Header Less file */
/* ########################################################## */


header {
     position: relative;
     z-index:3;
}
.header-container {
    //override default.
    font-size: @font-size-larger;
      z-index: 3;
      display:flex;
      align-items: center;
      .header-container__icon-menu {
          order:2;
        
      }
      .header-container__publication-menu {
          order:1;
          flex: 1 1 auto;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-end;
      }
      
      
    /* Mobile Buttons */    
	.form-group {
		width: 84%;
		float: left;
	}
	.navbar-form {
		.btn-default {
			margin-left: 10px;
		}
	}
	.logo {
	    float: left;
	    display:block;
	}


	.navbar-toggle {
		margin-right:0px;
		margin-bottom:0px;
		margin-top:0px;
		border-radius:0px;
	}
	.navbar {
		min-height:0;
		margin-bottom:0;
	}
	h1 {
		margin: 18px 0 10px;
		font-weight: 700;
	}
}

.main-header-container {
         position: fixed;
         right: 0;
         left: 0;
         &.is-main-header-container--scrolling {
             border-bottom: 2px solid fade(@link-color, 30%);
         }
}


.navbar-nav-main-menu {
    @icon-size-height: @font-size-base * 2.142857142857143;
    list-style: none;
    float:right;
    font-size: @font-size-larger;
    padding-left: 0;
    margin:0;
    > li {
        float:left;
    }
    .material-icons {
        font-size: @icon-size-height; //30px
    }
	.navbar-nav-main-menu__language-switcher-link {
        line-height: @icon-size-height; /*to match other menu items line height (which is the same as the font size */
        font-weight: bold;
    }
     > li > a { 
        padding-top: 5px;
        padding-bottom: 5px;
        line-height: 22px;
        position: relative;
        display: block;
        padding: 10px;
        &:visited, &:active, &:link {
            color: @masthead-icon-link-menu-colour;
        }
        &:hover, &:focus {
            text-decoration: none;
        }
        //this is for the new svg image icons
        img {
            height: @icon-size-height;
            //calculated using this tool https://angel-rs.github.io/css-color-filter-generator/
            filter: var(--masthead-colour-filter, brightness(0) saturate(100%) invert(23%) sepia(58%) saturate(0%) hue-rotate(246deg) brightness(92%) contrast(76%));
        }
         @media (max-width: @max-width-ultra-compact-ui) {
             padding: 10px 6px;
            }
        }
       
      
    
}
.navbar-nav.navbar-nav--publication-menu {
    float:right;
    padding-right: 50px;
    font-weight: bold;
    margin: 0; 
       @media (max-width: 889px) {
            display: none;
       }    
}

.navbar-left-container {
	float: left;
	padding: 10px 0;
	width: 120px;
	.img-responsive {
		width: 100%;
	}
}
.navbar-right-container {
	font-size: 25px;
	float: right;
	margin-top: 0;
	.navbar-toggle {
		float: right;
		padding: 10px 15px 9px;
		border: none;
		font-size: 25px;
		color: @brand-secondary;
		.active, &:hover {
			color: @navbar-default-bg;
			background: @brand-secondary;
		}
	}
	.navbar-toggle[aria-expanded=true] {
		color: @navbar-default-bg;
		background-color: @brand-primary;
		border-color: @brand-secondary;
		&:hover {
			color: @navbar-default-bg;
			background-color: @brand-primary;
			border-color: @brand-secondary;
		}
	}
}
.main-nav {
	li {
		text-align: center;
	}
}
.secondary-nav {
	li {
		text-align: center;
		font-size: @font-size-small;
		float: left;
	}
	.cart {
		display: none;
	}
}
.dropdown-menu {
	.dropdown-menu__item {
		text-align: left;
	}
	.dropdown-menu__nonlink {
	     padding: 10px 20px;
	     color: @gray;
	     white-space: nowrap;
	     display:block;
	}
	.journal-list__compact .dropdown-menu__nonlink {
		 padding-top: 0;
	}
}

.main-banner-mobile {
	.navbar-toggle {
		&:hover {
			color: @brand-secondary;
			background-color: transparent;
			border-color: transparent;
		}
		&:focus {
			color: @brand-secondary;
			background-color: transparent;
			border-color: transparent;
		}
		&.active {
			color: @navbar-default-bg;
			background-color: @brand-primary;
			border-color: @brand-secondary;
			position: relative;
			z-index: 1;
/*			&:after {
			    position: fixed;
			    content: "";
			    display: block;
			    left: 0;
			    top: 0;
			    height: 100%;
			    width: 100%;
			    z-index: -1;
			}*/
		}
	}
}
.navbar-mobile {
	.top-most-navigation {
		background-color: @brand-secondary;
		li {
			a {
				color: @default-background-colour;
			}
			&:first-child {
				a {
					color: @search-highlighted-text;
					white-space: nowrap;
					text-overflow: ellipsis;
					max-width: 120px;
					overflow: hidden;
					display: inline-block;
					vertical-align: middle;
					text-transform: uppercase;
				}
			}
		}
		.list-inline {
			margin-top: 3px;
			margin-bottom: 3px;
		}
	}
	.main-banner-mobile {
		background-color: @navbar-default-bg;
		box-shadow: 0 2px 7px @dborder-color;
		h1 {
			margin-top: 10px;
		}
	}
}
#bellowheadercontainer {
	.navbar-toggle {
		font-weight: bold;
	}
	.navbar-toggle.back-page-button {
		position: absolute;
		top: 50px;
		left: 0;
	}
	@media print {
		.navbar-toggle {
			display: none;
		}
	}
}
button[aria-expanded=true] {
	color: @dropdown-default-expanded-button-color;
}

.navbar-collapse.collapse {
	outline: none;
}
.navbar-form {
	.form-control {
		width: 100%;
	}
	.basket-link {
		display: block;
		width: 20px;
		float: right;
		padding-left: 10px;
	}
}

.breadcrumb {
	.inactiveLink {
		color: @brand-tertiary;
	}
}
.logo-image {
	min-width: 97px;
}
#homepage {
	.subjectscontainer {
		.nav-justified {
			li {
				padding: 0 3px;
				margin: 0;
				a {
					border: 1px solid  @nav-pills-active-link-hover-color;
				}
			}
		}
	}
}

.has-megamenu.open .mega-dropdown-menu {
    display:block;
}


.navbar-toggle {
    padding: 4px 8px;
}

@media (min-width: @screen-desktop) {

	.global-search-form {
	    position: static;
	    top: auto;
	    width:auto;
	    right: auto;
	    border: none;
	    max-width: none;
	    transform: none;
	    padding:0;
	    margin-right: -26px;
	    .form-group,.btn {
		    margin-top: 0;
	    }
	    .form-group {
	        width: 375px;
	    }
	    .btn-link {
	    	color: @gray;
	    	&:hover, &:focus {
	    		color: @gray;
	    	}
	    }
	}
}
@media (min-width: @screen-md-min) {
	.has-megamenu {
		z-index: 90; //put it above the mega-menu!
		a {
			margin: 0;
			&.activated {
				background-color: @default-background-colour !important;
				color: @mega-menu-active-link !important;
				&:after {
					position: absolute;
					left: 0;
					bottom: -1px;
					height: 1px;
					width: 100%;
					background: @default-background-colour;
					content: "";
				}
			}
		}
	}
	.overlay-blank {
		position: fixed;
		left: 0; top: 0;
		height: 100%;
		width: 100%;
		z-index: 99;	
	}
	.mega-dropdown-menu {
		display: none;
	    margin: 0;
	    padding: 0 15px;
	    z-index: 88;
	    background: none;
	    box-shadow: none;
	    border-radius: 0;
	    border: none;
	    position: absolute;
	    right: 0;
	    width: 100%;
	    top: 100%;
		&.navbar-collapse {
			&.collapse {
				display: none !important;
				&.in {
					display: block !important;
				}
			}
		}
		.mm-bg {
			width: 100%;
			display: table;
			display: -webkit-flex;
			display: flex;
			justify-content: flex-end;
			-webkit-justify-content: flex-end;
			float: right;
			margin: 0;
			padding: 0;
			border-right: 1px solid @mega-menu-border-color;
			border-top: 1px solid  @mega-menu-border-color;
			> li {
				display: table-cell;
				background: @default-background-colour;
				-webkit-flex: 1;
				-ms-flex: 1;
				flex: 1;
				min-width: 16.66%;
				max-width: 210px;
				border-bottom: 1px solid @mega-menu-border-color;
				padding: 20px 10px;
				&:first-child {
					border-left: 1px solid @mega-menu-border-color;
				}
				> a {
					&:first-child {
						padding: 10px;
						color: @grey3;
						font-weight: bold;
						display: block;
						margin-bottom: 10px;
						border-bottom: 1px solid @gray-lighter;
						&:hover {
							text-decoration: none;
						}
					}
				}
				> ul {
					list-style-type: none;
					display: block !important;
					background: none;
					padding: 0;
					> li {
						text-align: left;
						white-space: normal;
						 padding: 7px 10px;
						> a {
							background: none;
							color: @mega-menu-link-color;
							&:hover {
								background: @mega-menu-hover-bg;
								text-decoration: none;
								color: @mega-menu-hover-color;
								opacity: 1;
							}
						}
					}
				}
			}
		}
		&.dl-menuwrapper {
			margin: 0;
			max-width: none;
			.dl-trigger {
				display: none;
			}
		    li {
		    	&.dl-back:after, > a:not(:only-child):after {
		    		display: none;
		    	}
		    	&.dl-back {
		    		display: none;
		    	}
		    	a {
		    		font-size: 14px;
		    	}
			}
		}
		.dl-menu {
			position: static;
			opacity: 1;
			pointer-events: auto;
			transform: translateY(0px);
			-webkit-transform: translateY(0px);
		}

	}
	.menu-bar {
	    background-color: @brand-secondary;
	    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    	.dl-submenu {
			position: absolute;
			left: -1px;
			top: 100%;
			display: none;
		    z-index: 1100;
		    float: left;
		    min-width: 160px;
		    padding: 5px 0;
		    list-style: none;
		    font-size: 14px;
		    text-align: left;
		    background-color: @default-background-colour;
		    border: 1px solid rgba(0, 0, 0, 0.15);
		    border-top: none;
		    border-radius: 0 0 4px 4px;
		    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
		    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
		    background-clip: padding-box;
		    li {
		    	list-style-type: none;
		    	a {
					display: block;
					padding: 7px 20px;
					clear: both;
					font-weight: normal;
					line-height: 1.42857143;
					color: @grey3;
					white-space: nowrap;
					font-size: 14px;
					&:hover {
						background: #f5f5f5;
						color: @brand-secondary;
						text-decoration: none;
					}
		    	}
		    }
		}

	    .navbar-nav {
	    	padding: 0;
	    	position: relative;
	    	> li {
	    		> a {
					&.cart-button-desktop {
						color: @default-background-colour;
						&:hover, &:focus {
							background: none;
						}
					    .cart-button-desktop__item-count {
					    }
					}
	    			.fa-lg {
	    				font-size: 21px;
	    			}
	    		}
	    	}
			span {
				a {
					color:@default-background-colour;
					padding: 15px 0;
					display: inline-block;
				}
			}
	    }

	    .dl-menuwrapper {
	    	width: auto;
	    	float: left;
	    	margin: 0;
	    	max-width: none;
			.dl-trigger {
				display: none;
			}
		    li {
		    	&.dl-back:after, > a:not(:only-child):after {
		    		display: none;
		    	}
		    	&.dl-back {
		    		display: none;
		    	}
		    	a {
		    		font-size: 14px;
		    	}
	    	}
	    }

	    .dl-menu {
	    	margin-left: 1px;
	    	margin-top: 0;
	    	position: static;
	    	opacity: 1;
	    	pointer-events: auto;
	    	transform: translateY(0px);
	    	-webkit-transform: translateY(0px);
	    	background: none;
	    }

	}
	.navbar-nav {
		> li {
			&.dropdown {
				&:hover {
					&.has-megamenu {
						> a {
							color: @navbar-default-bg;
							background: @brand-secondary;
					
						}
					}
   					.dl-submenu{
						display: block;
					}
					> a {
						background: @default-background-colour;
						color: @navbar-default-link-active-color;
					}
				
				}
				&.has-megamenu {
						> a:focus,
						> a:hover {
							color: @navbar-default-bg;
							background: @brand-secondary;
					
						}
				}
			}
		}
	}
}
@media screen and (min-width: @screen-md-min) and (max-width: @screen-md-max) { 
	.mega-dropdown-menu {
		margin: 0 15px;
		.mm-bg {
			> li {
				padding-left: 5px;
				padding-right: 5px;
				>a {
					&:first-child {
						font-size: 13px;
					}
				}
				 > ul {
				 	> li {
				 		> a {
				 			font-size: 13px;
				 		}
				 	}
				 }
			}
		}
	}
}

@media (max-width: @grid-float-breakpoint) {
	.navbar-inverse {
		.navbar-nav {
			.open {
				.dropdown-menu {
					>li {
						>a {
							color: @dropdown-link-color;
							&:hover {
								color: @dropdown-link-hover-color;
							}
							&:focus {
								color: @dropdown-link-hover-color;
							}
						}
					}
				}
			}
		}
	}
	.navbar-nav {
		margin-top: 0;
		margin-bottom: 0;
		.open {
			background-color: @open-dropdown;
		}
	}
}
@media (max-width: @screen-md-max) {
	.navbar-inverse {
		.navbar-nav {
			padding: 0;
		}
	}
}
@media (max-width:@screen-xxs-max) {
	header{
		h1{
			font-size: 20px;
		}
	}
	.navbar-left-container {
	    width: 90px;
	    padding-top: 15px;
	}

	.navbar-right-container{
		.navbar-toggle{
		    font-size: 20px;
		    padding: 10px;
		    color: @brand-secondary;
	    }
	}

	.global-search-form{
	    margin: auto;
	    max-width: 99%;
	    .form-group{
	        width: 100%;
	        float: none;
	    }
	}
}



 .main-logo-image {
 	height: 70px;
    margin: 0;
 }
 
 #bellowheadercontainer {
    padding-top: 90px;
 }

 #homepage #bellowheadercontainer {
    padding-top: 0;
 }
 
 @media (max-width:@screen-sm-max) {
     .main-logo-image {
         height: 40px;
         margin: 0 0 0 10px;
         width: auto;
     }
     
     .main-header-container {
         z-index: 1030;
     }
     #bellowheadercontainer {
        padding-top: 60px; //so does not overlap static menu.
    }
}

@media (max-width:@max-width-ultra-ultra-compact-ui) {
   .header-container .header-container__icon-menu {
       display:flex;
   }
   .main-logo-image {
       height: 32px;
       margin: 0 0 0 5px;
   }
}

.navbar-article-tools-mobile {
    display:none;
}
 @media (max-width:@screen-sm-max) {
     header .header-container {
         padding:0;
     }
     .navbar-article-tools-mobile {
        display: block;
         .navbar-article-tools-mobile__link {
             color: @default-background-colour;
             padding: 10px;
             float: left;
             line-height: 30px;
         }
         
         .navbar-article-tools-mobile__menu-left {
             list-style:none;
             padding-left: 0;
             float:left;
             margin-bottom:0;
         }
         .navbar-article-tools-mobile__menu-right {
             list-style:none;
             padding-left: 0;
             float:right;
             margin-bottom:0;
         }
         .navbar-article-tools-mobile__item {
             float:left;
         }
         
    }
 }

.dropdown-toggle--position-mod {
    position: relative;
     @media (max-width:1400px) {
         .dropdown-menu {
             right: -50px;
             left:auto;
         }
    }
    
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
/* IE10+ specific styles go here */ 
    .main-logo-image {
        width:100%;
        height: 80px;
        margin: 0;
    }
}

