

a[rel="external"] {
	background: url("/images/jp/external-link-icon.gif") no-repeat scroll 99% 40% transparent;
	padding-right: 16px;
}

.externallink .fa-external-link {
	font-size:0.7em;
}


/* ############################ */
/* Bootstrap Generic overrides */


/* .container  has 15 pixles either side */
.no-padding {
	.no-padding;
}

.add-padding {
	padding:0 15px;
}

.no-padding-left{
	padding-left:0;
}

.no-padding-right {
	padding-right:0;
}

.publisherinfodiv h3 > a {
    text-decoration: @font-decoration-browse-item-title;
}

.publisherinfodiv__anchor-link {
    position: absolute;
    left: 0px;
    top: -@masthead-height;
    @media (max-width: 844px) {
    	top: -58px
    }
}	
/* default not to show headings */
.header-sidebar {
	.share-nav, .tools-nav {
		position: @mobile-menu-position;
		top: -10px;
		right: 15px;
		z-index: 88;
		box-shadow: 0 4px 15px rgba(0,0,0,0.2);
		-webkit-box-shadow: 0 4px 15px rgba(0,0,0,0.2);
		overflow: hidden;
		&.in {
			overflow: visible;
		}
		.panel-heading {
			display:none;
		}
		.panel-body > {
			ul > {
				li {
					display: block;
				}
			}
		}
	}
	.share-nav {
		/*right: 105px;*/
	}
	label {
		display:none;
	}
}

.relative {
	position: relative;
}

.form-control option {
	padding:5px;
}
.small-print { 
	list-style:none;
	padding:0 20px;
	.navbar-text {
		line-height: 0.6em;
		margin: 15px 0;
		padding: 0 10px;
		float:left;
		border-right: 1px solid @midnight-black; /* the colour should be a variable */
		&:last-child {
			border:none;
		}
	}
}

/* This should be used for all hiddendivs that are used to pass data from the front end to the backend */
.hiddenjsdiv,
.hidden-js-div,
.hiddenblurbsvalues,
.hiddenElement {
	display:none;
}

.hidden-js-toggle {
	display:none;
}
.hidden-js-li {
	display:none;
}
.loginDropdown {
	position:absolute;
	top:65px;
	left:8px;
	z-index:90;
	background-color:white;
	.fa-times {
		position:absolute;
		top:3px;
		right:3px;
	}
}


.showFacetsInternal .btn-filter {
	float:right;
	margin-bottom: 20px;
	&.search-filter-slide {
		margin: 10px;
	}
}

/* pulled from AIP for facets! Oh DEAR not mobile first!  */

@media (min-width:@screen-sm-min) {
	.search-facet-slide-container {
		opacity: 1;
	}
	#facetFilter {
		position: static;
		display: block !important;
		.modal-dialog {
			width: auto;
			margin: 0;
			-ms-transform: unset;
			-webkit-transform: unset; /* Chrome, Safari, Opera */
			transform: unset;
			.modal-content {
				box-shadow: none;
				border: none;
			}
		}
		.facetModelHeader {
			.navbar-toggle {
			}
		}
		.facetModelContainer {
			padding: 0;
			border: none;
			border-radius: 0;
			.facets{
			}
		}
	}
}  

/* pulled from AIP for facets! Oh DEAR not mobile first!  */
@media screen and (max-width:@screen-sm-max) {

	.header-sidebar {
		.share-nav, .tools-nav {
			border: none;
			min-width: 210px;
			.panel-body > {
                padding: 0;
				ul > {
                    margin: 0;
					>li {
						padding: 0;
						> .link-style, > a {
                            display: block;
                            border-top: 1px solid #eee;
						}
                        .togglecontent {
	                        margin: 5px 10px 5px 10px;
                        }
					}
				}
			}
		}
		
		.share-nav {
			padding: 10px;
		}
		.tools-nav {
			padding: 0;
			.panel-body > {
                padding: 0;
				ul > {
                    margin: 0;
					>li {
						padding: 0;
						> .link-style, > a {
                            padding: 10px 10px;
                        }
                    }
                }
            }
		    &.on-searchResults {
		        height: 0;
		        display: block;
		        &.in {
		        	height: auto;
		        }
		    }
		}
	}  

}
// this is currrently needed for the other listing pages so don't remove.
.browse-item {
    margin-bottom: 10px;
}


.search__item {
    margin-bottom: 40px;
    .authors__box,.makevisible{
		position: relative;
		padding-right: 43px;
    }
}



/* end of - Bootstrap Generic overrides */
/* #################################### */

/*#####################################*/
/* helpful style @todo is this in the correct place? */
.link-style{
	//make this look like a link
	color:@link-color;
	cursor: pointer;
	&:hover {
		text-decoration: underline;
		color: @link-hover-color;
	}	
}

/* .row has 15px margin on both sides */
.no-margin {
	margin: 0px;
}


/* ################################### */
/* Search Results page */

/* auto complete */
.ui-menu .ui-menu-item a { 
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

 /* search page tabbing - poss move to generic area 
  *  What does this relate too needs a relevent containing class! 

.inactiveLink {
    background-color: @default-background-colour;
    border-color: #dddddd #dddddd transparent;
    border-image: none;
    border-style: solid;
    border-width: 1px;
    color: #555555;
    cursor: default;
	border-radius: 4px 4px 0 0;
    line-height: 1.42857;
    margin-right: 2px;    
    display:block;
    padding:10px 15px;
}
*/

/*  Facet search area */ 
/* select option on facets */
.facet-container {
	.facets-toggle-span{
		display:block;
		width:96%;
		text-align:right;
		position:absolute;
		margin-top:-24px;
		cursor:pointer;
	}
	.list-group {
		display:none;
	}
}

.facetfilter {
	display:none;
}
.facets .hiddenprop {
	display:none;
}

/* metadata listing page */
.list-meta-container .showhide {
	text-transform: uppercase;
}


.tabbedpagecontainer {
	float:left;
	clear:both;
	padding:10px 0; 
}


/* 
 
 *  ahah icon styling - seems to be missing in so adding it again.
 */
    .keyicon  {
    	background-color: @brand-tertiary;
    	color: @default-background-colour;
    	display: inline-block;
    	font-size: 11px;
    	font-weight: bold;
    	line-height: 12px;
    	margin-right: 5px;
    	padding: 3px 0;
    	text-align: center;
    	text-transform: uppercase;
    	width: 22px;
    	vertical-align: middle; 
    	float: left;   
    }
    
 .content__keyicon {
     height: @font-size-h4; // this is the default!
 }
.accessIcons {
    position: relative;
    > a {
    display:block;
    margin-right: 18px;
    text-decoration: @font-decoration-browse-item-title; 
    }
  .content__keyicon {
     height: @font-size-h4; // this is the default!
     float:left;    
     padding-right:5px;
 }
    > i {
        float:left;
    }
}
    
.itemInListTitle {
    > a {
         text-decoration: @font-decoration-browse-item-title;
    }
}
    
.icon-key {
    .keyicon {
        margin:3px 3px 3px 0;
    }
    .icon-key__item {
        float: left;
        width:100%;
    }   
}
/* Access Keys , layout for Access Keys next to Title in browseItem tag */

    .articleListContainer {
    	.list-meta-container {
    		padding-bottom:5px;	
    		margin-bottom:10px;
    		border-bottom: 1px dotted #ccc;
    		&:last-child {
    			margin-bottom:0;
    			border-bottom:none;
    		}
    	}
    }

    /* More / Less [+] and [-] now use fa-plus, fa-minus, plus BT collapse feature (some some bespoke js to toggle class names)*/
    .fa.fa-plus, .fa.fa-plus {
    	font-size: 80%;
    }
    /* end of Search Results page */
    /* ################################### */

    /* ################################### */
    /* journal article page */


    .ajax-loading {
    	background: @default-background-colour url(/images/admin/spinner.gif) no-repeat left 50%;
    	display: block;
    }

    #related_content ul.list-unstyled > li {
        padding-left: 4px;
        padding-right:4px;
    }

    /* full text icons / content type on article page*/
    .contentTypeOptions {
    	ul {
    		list-style:none;
    		padding:0px;
    		
    		li {
    			padding: 0px 5px;
    		}
    	}
    	.full-text-icon { 
    		font-size: @font-size-huge;
    		display: block;
    		float: left;
    		padding: 5px 10px 0 0;
    	}
    	.fa-file-xml-o:before,
    	.fa-file-html-o:before {
    		content: @fa-var-file-code-o;
    	}
    	.fa-file-epub_zip-o:before{
    		content: @fa-var-file-text;
    	}
    	.fa-file-doc-o:before,
    	.fa-file-docx-o:before{
    		content: @fa-var-file-word-o;
    	}
    	.fa-file-xlsx-o:before{
    		content: @fa-var-file-excel-o;
    	}
    	.fa-file-pptx-o:before{
    		content: @fa-var-file-powerpoint-o;
    	}
    }

    /* Figures tab on Article page */
    .singleFigureContainer {
    	img.modal-lg {
    		width:100%;
    	}
    }

    /* end of Journal Article page */
    /* ################################### */

    /* ################################### */

    .meta-list > li {
    	margin-bottom: 5px;
    	
    }

    a[disabled] {
    	cursor:not-allowed;
    }


    .fullwidth-container {
    	min-width:100%;
    }

    a.lessLink, a.lessLink2, a.lesslink {
    	display: none;
    }

    

/*
*
*   Full text styling added on a temp basis for fixing ahah calls within the Issue page
*   NEEDS TO BE STRIPPED OUT ONCE FULLTEXT IS USING PROPER HTML TAGS.
*
*/
.jp-bold {
	font-weight:bold;
}
.jp-italic {
	font-style:italic;
}
.jp-small {
	font-variant:small-caps;
}
.jp-strike {
	text-decoration:line-through;
}
.jp-sub {
	font-size:0.83em;
	vertical-align:sub;
}
.jp-sup {
	font-size:0.83em;
	vertical-align:super;
    line-height:0;
}
.jp-underline {
	text-decoration:underline;
}
.jp-w100 {
	width:100%;
}
.jp-w60 {
	width:60%;
}
.jp-border1 {
	border:solid 1px black;
}
.jp-border4 {
	border:solid 4px black;
}
.jp-td1 {
	padding:1px;
}
.jp-td5 {
	padding:5px;
}

/*
*
*   Full text styling added on a temp basis for fixing ahah calls within the Issue page
*   NEEDS TO BE STRIPPED OUT ONCE FULLTEXT IS USING PROPER HTML TAGS.
*
*/

.nav-pills {
	.itemFavourites {
		display: inline;
		.favouritesForm {
			display: inline;
			.addtofavourites {
				display: inline;
				a {
					display: block;
					padding: 10px;
					text-decoration: none;
					border-radius: 4px;
					-webkit-border-radius: 4px;
					&:hover {
					    text-decoration: none;
					    background-color: @gray-lighter;
					}
				}
			}
		}
	}
	&.nav-stacked { //nav-stacked behaviour is for mobile only layout overriden by media query in tablet.less
		padding-top:0;
		.tab-menu {
			> span {
				font-weight:bold;
				&:before {
					display: inline-block;
					font-family: FontAwesome;
					font-style: normal;
					font-weight: normal;
					line-height: 1;
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
					content: @fa-var-bars;
					padding-right:@padding-large-vertical;
				}
			}
		}
		.dropdown-menu {
			position: static; //override bootstrap defaults
			width:100%;
			padding:0;
		}
	}
}

#p2wRecaptcha {
	float:right;
	clear:both;
	padding-bottom: 15px;
	#timer_id {
		display: inline;
		margin: 5px;
		width: auto;
		float: none;
	}
}

.tandc-container {
	float:right;
}

.user-questions input.error {
    margin:4px -20px 0;
}
@media(max-width:@screen-xs-min){
	.tandc-container, #regForm #p2wRecaptcha, #regForm .submit-form-button-container input{
        float:none;
    }
    .tandc-container label.error,#regForm #p2wRecaptcha label.error{
        display:block !important;
    }
    .tandc-container input,#regForm #p2wRecaptcha input, #newregactivation .activationButton input{
        float:left !important;
    }
    #email-form .captcha-inline label.error{
        display:block !important;
        margin-top:-12px;
    }
    #regForm .regInput input,#regForm #p2wRecaptcha input {
   	vertical-align: middle;
    	margin-left:0 !important;
    }
}
.submit-form-button-container .btn {
	float:right;
	margin-bottom: 15px;
}

.panel-heading{
	position: relative;
	.panel-title {
		display: inline-block;
		padding-right: 13px;
		.rssicon{
			position: absolute;
			right: 13px;
			top: 12px;
		}
	} 
}
   
.articleMetadataInner {
    hr {
        margin-top: 0;
        margin-bottom:0;
        border-top: none;
    }
    .description.contain p:first-child {
        display:block;
    }
    .ellipseSeeMore {
	cursor:pointer;
    }
}

/* crumbs */

.breadcrumb > li {
    display:inline; //over ride bootstrap as this looks messy with long crumbs.
}

/* subject listing  */
.bullet-list {
    padding-left: 20px;
}

.btn.btn--unstyled:hover {
    color:@midnight-black;
}

.meta-data-block .meta-data-block__title {
   font-weight:bold;
}

.material-icons {
    vertical-align: middle;
    padding-bottom: 3px;
}
.background-image-main {
    position:relative;
    max-height: calc(~"60vh - @{masthead-height}");
    margin-top: @masthead-height;
    min-height: 400px;
    	.dropdown-menu {
            max-height: 195px; //set maximum height independent of viewport size;
        }
    @media (max-width:@screen-sm-max) {
       height: 300px; 
       margin-top: 50.85px; //this is to make way for the fixed menu.
	   overflow: hidden;
       display: flex;
       justify-content: center;
         .dropdown-menu {
            max-height: 160px;
        }
    }
   @media (max-height:850px) {
         min-height: 350px;
        .dropdown-menu {
        	max-height: 140px;
        }
    }
    overflow: hidden;
     margin-bottom: 20px;
    .background-image-main__title {
        position: absolute;
        left: 50%;
        top: 15%;
        font-size: 26px;
        font-weight: @home-page-banner-font-weight;
        background-color: transparent;
        @media   (min-width:@screen-md) and (max-width: 1100px)  {
            font-size: 45px;
        }
        @media (min-width:1101px) {
            font-size: 60px;
        }
        
        @media (max-width:463px) {
            font-size: 20px;
        }
        
        @media (max-width:357px) {
            font-size: 14px;
        }
        
        transform: translate(-50%,-50%);
        text-align: center;
        color: @default-background-colour;
        width:100%;
        text-shadow:
           -1px -1px 0 #000,  
           1px -1px 0 #000,
           -1px 1px 0 #000,
            1px 1px 0 #000;
        
    }
    .background-image-main__search {
        position: absolute;
        width: @home-page-hero-search-box-width;
        top: 200px;
        left: 50%;
        top: 47%;
        transform: translate(-50%,-47%);
        @media (max-width:1100px) {
            top:50%;
            transform: translate(-50%,-50%);
        }
        @media (max-width: @home-page-hero-search-box-width + 19px) {
            width: 98%;
        }
        
    }
    .background-image-main__search-form {
        position:relative;
        display:block;
        .background-image-main__search-button {
            position:absolute;
            right: 4px;
            top: 9px;
            padding: 3px;
            font-size: 30px;
            line-height: 21px;
            background-color: transparent;
            border-color: transparent;
            color: @text-color;

            @media (max-width:1100px) {
                font-size: 30px;
                right: 8px;
                
            }
            .material-icons {
               padding-bottom:0px;
            }
        }
        .background-image-main__search-filter {
        	@media (max-width:420px) {
                display:none;
            }
            position:absolute;
            left:0;
            top: 8px;
            padding: 3px;
                .btn-link {
                    color: @text-color;
                    text-decoration: none;
                    &:hover {
                       color: @text-color;
                       text-decoration: none;
                    }
                }
                .dropdown-menu {
                	overflow-y: auto;
                	overflow-x: hidden;
                    a {
                        padding: 3px 15px;
                        -moz-padding-end: 30px;
                    }
                  
                }
        }
        .background-image-main__advanced-search-link {
           @media (max-width:1100px) {
                display:none;
            }
            color: white;
            font-weight: 800;
             text-shadow:
               -1px -1px 0 #000,  
               1px -1px 0 #000,
               -1px 1px 0 #000,
                1px 1px 0 #000;
            &:visited, &:active &:hover {
                color: @default-background-colour;
            } 
        }
    }
    .background-image-main__image {
        @media (min-width:1090px) {
            transform: translateY(-30vh);
        }
        @media (min-width:1590px) {
             width:100%;
             /*this is temporary entry for the current 
             image to show the middle half of the image. */
             transform: translateY(-35vh);
        }
        min-height: 450px;
        background: url('@{platform-img-path}ajax-loader.gif') center 26% no-repeat @midnight-black;
        object-fit: cover;
    }
    .background-image-main__search-input {
      height: 55px;
      font-size:14px;
      padding: 6px 52px 6px 140px;
      @media (max-width:726px) {
            padding: 6px 52px 6px 140px;
      }

      @media (max-width:420px) {
            padding: 6px 52px 6px 12px;
      }

      @media (max-width:1100px) {
           &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
              color: @text-color;
            }
           &::-moz-placeholder { /* Firefox 19+ */
              color: @text-color;
            }
           &:-ms-input-placeholder { /* IE 10+ */
              color: @text-color;
            }
       
        }
       
    }
}

.dropdown .dropdown__button {
   &:hover, &:focus {
       text-decoration: none;
       outline: none;
   }
   .caret {
       margin-left: 4px;
       padding-bottom: 12px;
   }
   .dropdown__selected-text {
        display:inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: @dropdown-select-text-line-height;
    }

}

.background-image-main__search-filter {
    .dropdown__button  .dropdown__selected-text {
        max-width: 100px;
    }
}

.panel-default--featured-articles li {
    padding: 10px 0;    
}

.text-sentencecase {
    text-transform: lowercase;
}
.text-sentencecase:first-letter {
    text-transform: uppercase;
}

/* this is a change for Hybrid skin only to 
 * acommodate the fixed position header on page on 
 the edit inaccessible content page */
.eic-section > .panel-heading {
    position:relative;
} 

.eic-section .section-top {
    position: absolute;
    top: -90px;
    visibility: hidden;
}

.red-icon {
    color: #f00;
}

/* Modify search section for flat design ( Border less ) styles */
.searchRefine {
    &.navbar{
        border: none;
        background-color: @bg-transparent;
        float: left;
        clear: both;
        width: 100%;
        .modifyThisSearch{
            padding-left: 0;
        }
    }
}

/*For borderless login box*/ 
.header-sidebar{
    .login__box{
        .list-group-item {
            padding: 6px 0;
            border: none;
        }
    }
    .loginDropdown {
        top: 64px;
        left: 0px;
    }
}



