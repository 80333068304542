.paginator {
    .arrow {
        font-weight:bold;
    }
}

.paginator-container {
    p {
    display: inline;
    }
    display: inline-block;
}

.a_to_z_mobile {
    float: right;
}

.a_to_z {
    float: right;
    display: none;

    .pagination {
        margin:0;
         > li > a,
         > li > span {
             border: none;
             border-left: 1px solid @grey-light-custom;
         }

         li{
            a{
                &:hover{
                    color: @navbar-default-bg!important;
                }
            }
         }
    }
 
}

.facetContainer {
    padding:0;

    li.facetitem {
        padding-bottom: 3px;
    }
    .navbar-collapse {
         padding:0;
    }

    .browsefacetterm {
        padding: 5px 0;
    }
}
.publist-paginated {
    padding-right: 0;
    text-align: justify;
    .browse-item {
        float:left;
        clear: both;
        width:100%; //fix for IE11 text overflow issue see SABINET-96.
        > li {
            display:table;
            > div {
                display: table-cell;
                vertical-align: top;
            }

        }

    }
   .coverImageContainer {
       padding-right: 10px;
       width: 94px;
   }
   .showhide .minus {
       display: none;
   }
}

.facet-remove-options {
    .facet-remove-options__item {
        font-size: 18px;
    }
    .facet-remove-options__item-text-only {
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 400px;
        display:block;
    } 
    .facet-remove-options__remove-icon {
        margin-top:0;
    }
    .facet-remove-options__remove-icon.fa-stack-2x {
        font-size: 25px;
        padding-top: 6px;
    }
    
}
