@media (max-width: @screen-md-max) and (min-width: @responsive-tabs-icon-breakpoint + 1 ) {
    /* PLAT-530 elipsis tab long titles when looking on a tablet  */
    .icon-image .tabIcon .titleIcon{
            overflow: hidden;
            text-overflow: ellipsis;
    }
} 


@media (min-width: (@responsive-tabs-icon-breakpoint + 1)) {
   .icon-image ul.tab-dropdown .select {
        display:none;
    }
    .icon-image {
        ul{
             &.tabset{
                .tabIcon{
                    display: inline-block !important;
                }
             } 
        }
    }           
}

@media (max-width: @responsive-tabs-icon-breakpoint) {
    .icon-image {
        padding-top: 0;
        display: block !important;
         ul {
            &.tabset {
                text-align: center;
                li {
                    a {
                        min-height: 45px !important;
                        width: 65px !important;
                        padding-left: 5px !important;
                        padding-right: 5px !important;
                        .iconContainer {
                            > i {
                                height: 1em;
                                width: 1em;
                                font-size: 2em;
                                &.fa-lg {
                                    height: 2em;
                                    width: 2em;
                                    font-size: 1em;
                                }
                            }
                        }
                    }
                }
            }
            &.tab-dropdown{
                text-align: left;
                .tabIcon{
                    display: none;
                    width: 100% !important;
                    a{
                        text-align: left;
                        width: 100% !important;
                        min-height: auto;
                        border-bottom: 1px solid @dborder-color;
                        cursor: pointer;
                        padding-right: 17px;
                        padding-left: 17px;
                        .iconContainer{
                            i{
                                float: left;
                                margin-right: 7px;
                            }
                            .titleIcon{
                                margin-bottom: 5px;
                                font-size: 14px;
                                font-weight: normal;
                            }
                        }
                        &.active{
                            border-bottom: 1px solid @dborder-color !important;
                        }
                    }
                }
                &:after{
                    color: @brand-secondary;
                    display: block;
                    font-size: 20px;
                    top: 5px;
                    pointer-events: none;

                }
                .select{
                    .mobile-tab{
                        padding: 12px 7px;
                        cursor: pointer;
                        color: @brand-tertiary;
                        border-bottom: 3px solid @brand-tertiary;
                    }
                }
            }
        }
    }
    .transformer-tabs {
         ul::after {
          content: '\2630';
          position: absolute;
          top: 8px;
          right: 15px;
          z-index: 2;
          pointer-events: none;  
         }
    }
}
